import styled, { css } from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import BG from '../../../static/images/components/feature-bg.png';

export const FeaturedContainer = styled.div`
  background-image: url(${BG});
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 140px;
  padding-bottom: 140px;

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 506px;

    .cta-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${atMinWidth.lg`
      max-width: 770px;
      gap: 64px;
    `}

    ${atMinWidth.xl`
      max-width: 1170px;
      gap: 64px;
    `}

    .header-container {
      display: flex;
      gap: 20px;
      flex-direction: column;

      .header {
        color: var(--Base-White, var(--Color-Gray-white, #fff));
        font-family: CircularXX;
        font-size: 35.16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 48.345px */
        letter-spacing: -0.3px;
        ${atMinWidth.lg`
          font-size: 43.95px;
        `}
      }
      .subheader {
        color: var(--Gray-300, #c0c3d1);
        font-family: CircularXX;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 33.75px */
        letter-spacing: -0.27px;

        ${atMinWidth.lg`
          font-size: 22.5px;
        `}
      }
    }
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${atMinWidth.lg`
    gap: 30px;
  `}

  .divider {
    background: #343643;
    width: 100%;
    height: 2px;
  }
`;

export const FeaturedItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  .gatsby-image-wrapper {
    border-radius: 10px;
    img {
      transform-origin: center;
      transition: transform 300ms ease-in-out 0s !important;
    }
    ${props =>
      props.onHover &&
      css`
        img {
          transform: scale(1.1);
        }
      `}
    &:hover img {
      transform: scale(1.1);
    }
  }

  img {
    width: 518px;
    height: 292px;
    border-radius: 10px;
    flex-shrink: 0;

    ${atMinWidth.lg`
      width: 370px;
      height: 208.125px;
    `}
    ${atMinWidth.xl`
      width: 570px;
      height: 320.625px;
    `}
  }

  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    color: white;
    gap: 16px;

    ${atMinWidth.lg`
      gap: 0;
    `}

    .text-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 12px;
      ${atMinWidth.lg`
        gap: 16px;
      `}

      span.tags {
        margin-top: -5px;
        color: #86899a;
        font-family: CircularXX;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }

      h3 {
        a {
          color: var(--Color-Gray-white, #fff);
          font-family: CircularXX;
          font-size: 22.5px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 38.676px */
          letter-spacing: -0.3px;
          text-decoration: none;
          ${atMinWidth.lg`
            font-size: 28.13px;
          `}
          ${atMinWidth.xl`
            font-size: 35.16px;
          `}
        }
      }

      p {
        color: #a5a8b8;
        font-family: CircularXX;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        letter-spacing: -0.27px;
        ${atMinWidth.lg`
          font-size: 16px;
        `}
        ${atMinWidth.xl`
          font-size: 18px;
        `}
      }
    }

    div.cta {
      color: var(--Color-Gray-white, #fff);
      font-family: CircularXX;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.16px;
      a {
        color: var(--Color-Gray-white, #fff);
        font-family: CircularXX;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.16px;
      }
    }
  }
`;
